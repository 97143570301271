import { useQuery } from '@apollo/client';
var COMPASS_LAYOUTS_GET_ORGANIZATION_BY_LEARNING_SLUG_QUERY = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "CompassLayoutsGetOrganizationByLearningSlugQuery" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "organizationSlug" } }, type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "get_organization_by_learning_slug" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "organization_slug" }, value: { kind: "Variable", name: { kind: "Name", value: "organizationSlug" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "slug" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "original_id" }, arguments: [], directives: [] }] } }] } }], loc: { start: 0, end: 196, source: { body: "query CompassLayoutsGetOrganizationByLearningSlugQuery(\n  $organizationSlug: String!\n) {\n  get_organization_by_learning_slug(organization_slug: $organizationSlug) {\n    slug\n    original_id\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var useCentralizedOrgSlug = function useCentralizedOrgSlug(_ref) {
  var _data$get_organizatio, _data$get_organizatio2;
  var organizationSlug = _ref.organizationSlug;
  var _useQuery = useQuery(COMPASS_LAYOUTS_GET_ORGANIZATION_BY_LEARNING_SLUG_QUERY, {
      context: {
        clientName: 'v5'
      },
      variables: {
        organizationSlug: organizationSlug
      },
      skip: !organizationSlug
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  return {
    centralizedOrgSlug: (_data$get_organizatio = data === null || data === void 0 ? void 0 : (_data$get_organizatio2 = data.get_organization_by_learning_slug) === null || _data$get_organizatio2 === void 0 ? void 0 : _data$get_organizatio2.slug) !== null && _data$get_organizatio !== void 0 ? _data$get_organizatio : '',
    loading: loading,
    error: !!error
  };
};
export default useCentralizedOrgSlug;