import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
var getCSSStyleVal = function getCSSStyleVal(str, parentNum) {
  if (!str) return 0;
  var strVal = str.includes('px') ? +str.split('px')[0] : str.includes('%') ? +str.split('%')[0] * parentNum * 0.01 : str;
  return Number.isNaN(+strVal) ? 0 : +strVal;
};
export var getRealShape = function getRealShape(el) {
  var defaultShape = {
    width: 0,
    height: 0
  };
  if (!el || typeof window === 'undefined') return defaultShape;
  var rect = el.getBoundingClientRect();
  var _window$getComputedSt = window.getComputedStyle(el),
    width = _window$getComputedSt.width,
    height = _window$getComputedSt.height;
  return {
    width: getCSSStyleVal("".concat(width), rect.width),
    height: getCSSStyleVal("".concat(height), rect.height)
  };
};
var useRealShape = function useRealShape(ref) {
  var _useState = useState({
      width: 0,
      height: 0
    }),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  var update = function update() {
    var _getRealShape = getRealShape(ref.current),
      width = _getRealShape.width,
      height = _getRealShape.height;
    setState({
      width: width,
      height: height
    });
  };
  useEffect(function () {
    return update();
  }, [ref.current]);
  return [state, update];
};
export default useRealShape;