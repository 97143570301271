import { DEFAULT_DISTANCE_FROM_CONTAINER } from "../constants";
export var getIdealPositon = function getIdealPositon() {
  var distanceFromContainer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_DISTANCE_FROM_CONTAINER;
  return "calc(100% + ".concat(distanceFromContainer, "px)");
};
var overflowsLeft = function overflowsLeft(content) {
  return content.x < 0;
};
var overflowsRight = function overflowsRight(content) {
  return content.x + content.width > window.innerWidth;
};
var overflowsTop = function overflowsTop(content) {
  return content.y < 0;
};
var overflowsBottom = function overflowsBottom(content) {
  return content.y + content.height > window.innerHeight;
};
export var adjustLaterallyIfNeeded = function adjustLaterallyIfNeeded(contentRect) {
  if (overflowsLeft(contentRect)) return {
    left: '-5px',
    right: ''
  };
  if (overflowsRight(contentRect)) return {
    right: '-5px',
    left: ''
  };
  return {};
};
export var adjustVerticallyIfNeeded = function adjustVerticallyIfNeeded(content) {
  if (overflowsTop(content)) return {
    top: '0px',
    bottom: ''
  };
  if (overflowsBottom(content)) return {
    bottom: '0px',
    top: ''
  };
  return {};
};
export var defaultPositionAdjustmentIfOverflowsVertically = function defaultPositionAdjustmentIfOverflowsVertically(content, offset) {
  if (overflowsTop(content)) return {
    newPosition: 'bottom',
    top: getIdealPositon(offset),
    bottom: ''
  };
  if (overflowsBottom(content)) return {
    newPosition: 'top',
    bottom: getIdealPositon(offset),
    top: ''
  };
  return {};
};
export var defaultPositionAdjustmentIfOverflowsHorizontally = function defaultPositionAdjustmentIfOverflowsHorizontally(content, offset) {
  if (overflowsLeft(content) || overflowsRight(content)) return {
    newPosition: 'top',
    bottom: getIdealPositon(offset),
    left: '',
    right: ''
  };
  return {};
};
export var getIdealBoundingRect = function getIdealBoundingRect(_ref) {
  var container = _ref.container,
    content = _ref.content,
    position = _ref.position;
  var yCenter = container.getBoundingClientRect().y + container.getBoundingClientRect().height / 2;
  var xCenter = container.getBoundingClientRect().x + container.getBoundingClientRect().width / 2;
  var _content$getBoundingC = content.getBoundingClientRect(),
    width = _content$getBoundingC.width,
    height = _content$getBoundingC.height;
  var contentX;
  var contentY;
  if (position === 'top' || position === 'bottom') contentX = xCenter - width / 2;else if (position === 'left') contentX = container.getBoundingClientRect().x - width - 20;else contentX = container.getBoundingClientRect().x + container.getBoundingClientRect().width + 20;
  if (position === 'left' || position === 'right') contentY = yCenter - height / 2;else if (position === 'top') contentY = container.getBoundingClientRect().y - height - 20;else contentY = container.getBoundingClientRect().y + container.getBoundingClientRect().height + 20;
  return {
    x: contentX,
    y: contentY,
    width: width,
    height: height
  };
};
export var isLateralPositionAndOverflowing = function isLateralPositionAndOverflowing(position, rect) {
  return (position === 'left' || position === 'right') && (overflowsRight(rect) || overflowsLeft(rect));
};