import { useQuery } from '@apollo/client';
var COMPASS_LAYOUTS_GET_FLAG_ORGANIZATION = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "CompassLayoutsGetFlagOrganization" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "slug" } }, type: { kind: "NamedType", name: { kind: "Name", value: "String" } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "b2bOrganizationCurrent" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "slug" }, value: { kind: "Variable", name: { kind: "Name", value: "slug" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "originalId" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "features" }, arguments: [], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "originalId" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "name" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "slug" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "scope" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "isActive" }, arguments: [], directives: [] }] } }] } }] } }], loc: { start: 0, end: 205, source: { body: "query CompassLayoutsGetFlagOrganization($slug: String) {\n  b2bOrganizationCurrent(slug: $slug) {\n    originalId\n    features {\n      originalId\n      name\n      slug\n      scope\n      isActive\n    }\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var useFlagOrganization = function useFlagOrganization(_ref) {
  var _data$b2bOrganization, _data$b2bOrganization2;
  var flag = _ref.flag,
    organizationSlug = _ref.organizationSlug;
  var _useQuery = useQuery(COMPASS_LAYOUTS_GET_FLAG_ORGANIZATION, {
      variables: {
        slug: organizationSlug
      },
      context: {
        clientName: 'v4.b2b'
      },
      fetchPolicy: 'network-only',
      skip: !organizationSlug
    }),
    data = _useQuery.data,
    loading = _useQuery.loading;
  var currentFlag = data === null || data === void 0 ? void 0 : (_data$b2bOrganization = data.b2bOrganizationCurrent) === null || _data$b2bOrganization === void 0 ? void 0 : (_data$b2bOrganization2 = _data$b2bOrganization.features) === null || _data$b2bOrganization2 === void 0 ? void 0 : _data$b2bOrganization2.find(function (feature) {
    return (feature === null || feature === void 0 ? void 0 : feature.slug) === flag;
  });
  return {
    isActive: (currentFlag === null || currentFlag === void 0 ? void 0 : currentFlag.isActive) || false,
    isLoading: loading
  };
};
export default useFlagOrganization;