import { useQuery } from '@apollo/client';
import { useMe } from '@crehana/auth';
var COMPASS_LAYOUTS_GET_FEATURE_FLAG = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "CompassLayoutsGetFeatureFlag" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "nameKey" } }, type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }, directives: [] }, { kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "organizationId" } }, type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "feature_flag" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "name_key" }, value: { kind: "Variable", name: { kind: "Name", value: "nameKey" } } }, { kind: "Argument", name: { kind: "Name", value: "organization_id" }, value: { kind: "Variable", name: { kind: "Name", value: "organizationId" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "category" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "name_key" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "namespace_key" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "value" }, arguments: [], directives: [] }] } }] } }], loc: { start: 0, end: 209, source: { body: "query CompassLayoutsGetFeatureFlag($nameKey: String!, $organizationId: Int) {\n  feature_flag(name_key: $nameKey, organization_id: $organizationId) {\n    category\n    name_key\n    namespace_key\n    value\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
export var useFeatureFlagByNameKey = function useFeatureFlagByNameKey(_ref) {
  var _me$organization, _data$feature_flag;
  var nameKey = _ref.nameKey;
  var _useMe = useMe(),
    me = _useMe.me;
  var _useQuery = useQuery(COMPASS_LAYOUTS_GET_FEATURE_FLAG, {
      fetchPolicy: 'no-cache',
      variables: {
        nameKey: nameKey,
        organizationId: me === null || me === void 0 ? void 0 : (_me$organization = me.organization) === null || _me$organization === void 0 ? void 0 : _me$organization.originalId
      },
      context: {
        clientName: 'v5'
      }
    }),
    loading = _useQuery.loading,
    error = _useQuery.error,
    data = _useQuery.data;
  return {
    loading: loading,
    error: error,
    value: Boolean(data === null || data === void 0 ? void 0 : (_data$feature_flag = data.feature_flag) === null || _data$feature_flag === void 0 ? void 0 : _data$feature_flag.value)
  };
};
export default useFeatureFlagByNameKey;