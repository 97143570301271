import { useEffect } from 'react';
var DEFAULT_OPTIONS = {
  delay: 200
};
export var useOnScreenVariation = function useOnScreenVariation(callback) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_OPTIONS;
  useEffect(function () {
    var isScrolling;
    var scrollListener = function scrollListener() {
      var _options$delay;
      clearTimeout(isScrolling);
      isScrolling = setTimeout(function () {
        callback();
      }, (_options$delay = options.delay) !== null && _options$delay !== void 0 ? _options$delay : DEFAULT_OPTIONS.delay);
    };
    if (options.runAtStart) scrollListener();
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);
    return function () {
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    };
  }, [callback, options.delay, options.runAtStart]);
};